.custom-template {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: -325px;
  width: 325px;
  height: max-content;
  display: block;
  z-index: 1;
  background: #ffffff;
  transition: all 0.3s;
  z-index: 1003;
  box-shadow: -1px 1px 20px rgba(69, 65, 78, 0.15);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  transition: all 0.5s;
}
body {
  font-family: "Montserrat", sans-serif !important;
  letter-spacing: 0.5px !important;
  font-size: 16px !important;
  color: #000 !important;
}
.custom-template.open {
  right: 0px;
}
.bar-list {
  border-left: 0px solid #000;
  border-bottom: 0px solid #000;
}
.bg-primary-gradient a:hover {
  color: #fff;
}
.profile-btn {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 5px;
  font-weight: 500;
  color: #002866 !important;
  justify-content: center;
  font-size: 12px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #002866;
}
.profile-btn:hover {
  background-color: #f9cb40 !important;
  color: #002866 !important;
  border-color: #f9cb40 !important;
}
.fun-donate-btn1 {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #002866 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 20px;
  cursor: pointer;
  border: 1px solid #002866;
}
.fun-donate-btn2 {
  border-radius: 8px;
  font-weight: 600;
  color: #fff;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 8px 20px;
  cursor: pointer;
  border: 1px solid #fff;
}
.fun-donate-btn1:hover {
  background-color: #f9cb40 !important;
  color: #002866 !important;
  border-color: #f9cb40 !important;
}
.fun-donate-btn2:hover {
  background-color: #f9cb40 !important;
  color: #002866 !important;
  border-color: #f9cb40 !important;
}
.btn__primary1 {
  background-color: #002866;
  color: white;
  border-radius: 8px;
}
.buttonPrimary1 {
  transition: all 0.5s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-weight: 600;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  color: white;
  border-radius: 8px;
  padding: 10px 20px;
  cursor: pointer;
  align-items: center;
  border: 0;
}
.buttonPrimary1:hover {
  color: #002866 !important;
  background-color: #f9cb40 !important;
}
.custom-template .custom-toggle {
  position: absolute;
  width: 45px;
  height: 45px;
  background: rgb(88, 103, 221);
  top: 50%;
  left: -45px;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-right: 1px solid #177dff;
  cursor: pointer;
  color: #ffffff;
  box-shadow: -5px 5px 20px rgba(69, 65, 78, 0.21);
}

.custom-template .custom-toggle i {
  font-size: 20px;
  animation: 1.3s spin linear infinite;
}

.custom-template .title {
  padding: 15px;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  border-top-left-radius: 5px;
  border-bottom: 1px solid #ebedf2;
  background: #5867dd;
}
#forgotPasswod.modal-dialog {
  max-width: 500px;
  margin: 1.75rem auto;
}
.custom-template .custom-content {
  padding: 20px 15px;
  max-height: calc(100vh - 90px);
  overflow: auto;
}

.custom-template .switcher {
  padding: 5px 0;
}

.custom-template .switch-block h4 {
  font-size: 13px;
  font-weight: 600;
  color: #444;
  line-height: 1.3;
  margin-bottom: 0;
  text-transform: uppercase;
}

.custom-template .btnSwitch {
  margin-top: 20px;
  margin-bottom: 25px;
}

.custom-template .btnSwitch button {
  border: 0px;
  height: 20px;
  width: 20px;
  outline: 0;
  margin-right: 10px;
  margin-bottom: 10px;
  cursor: pointer;
  padding: 0;
  border-radius: 50%;
  border: 2px solid #eee;
  position: relative;
  transition: all 0.2s;
}

.custom-template .btnSwitch button:hover {
  border-color: #0bf;
}

.custom-template .btnSwitch button.selected {
  border-color: #0bf;
}

.custom-template .img-pick {
  padding: 4px;
  min-height: 100px;
  border-radius: 5px;
  cursor: pointer;
}

.custom-template .img-pick img {
  height: 100%;
  height: 100px;
  width: 100%;
  border-radius: 5px;
  border: 2px solid transparent;
}

.custom-template .img-pick:hover img,
.custom-template .img-pick.active img {
  border-color: #177dff;
}

.demo .btn,
.demo .progress {
  margin-bottom: 15px !important;
}

.demo .form-check-label,
.demo .form-radio-label {
  margin-right: 15px;
}

.demo .toggle,
.demo .btn-group {
  margin-right: 15px;
}

.demo #slider {
  margin-bottom: 15px;
}

.table-typo tbody > tr > td {
  border-color: #fafafa;
}

.table-typo tbody > tr > td:first-child {
  min-width: 200px;
  vertical-align: bottom;
}

.table-typo tbody > tr > td:first-child p {
  font-size: 20px;
  color: #333;
}

.demo-icon {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  padding: 10px;
  transition: all 0.2s;
}

.demo-icon:hover {
  background-color: #f4f5f8;
  border-radius: 3px;
}

.demo-icon .icon-preview {
  font-size: 1.8rem;
  margin-right: 10px;
  line-height: 1;
  color: #333439;
}

body[data-background-color="dark"] .demo-icon .icon-preview {
  color: #969696;
}

.demo-icon .icon-class {
  font-weight: 300;
  font-size: 13px;
  color: #777;
}

body[data-background-color="dark"] .demo-icon .icon-class {
  color: #a9a8a8;
}

.form-show-notify .form-control {
  margin-bottom: 15px;
}

.form-show-notify label {
  padding-top: 0.65rem;
}

.map-demo {
  height: 300px;
}

#instructions li {
  padding: 5px 0;
}

.row-demo-grid {
  margin-bottom: 15px;
}

.row-demo-grid [class^="col"] {
  text-align: center;
}

.row-demo-grid [class^="col"] .card-body {
  background: #ddd;
}

.btnSwitch button[data-color="white"] {
  background-color: #fff;
}
.btnSwitch button[data-color="grey"] {
  background-color: #f1f1f1;
}
.carousel-caption h5 {
  color: #fff !important;
}
.page-title,
.card-title {
  color: #002866 !important;
}
.btnSwitch button[data-color="black"] {
  background-color: #191919;
}
.btnSwitch button[data-color="dark"] {
  background-color: #1a2035;
}
.card-body h4,
.card-body h1 {
  color: #fff !important;
}
.flex1 {
  flex: 1;
}
.flex2 {
  flex: 2;
}
.ant-table-cell {
  max-width: 180px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.btnSwitch button[data-color="blue"] {
  background-color: #1572e8;
}
.btnSwitch button[data-color="purple"] {
  background-color: #6861ce;
}
.btnSwitch button[data-color="light-blue"] {
  background-color: #48abf7;
}
.btnSwitch button[data-color="green"] {
  background-color: #31ce36;
}
.btnSwitch button[data-color="orange"] {
  background-color: #ffad46;
}
.btnSwitch button[data-color="red"] {
  background-color: #f25961;
}
.btnSwitch button[data-color="dark2"] {
  background-color: #1f283e;
}
.btnSwitch button[data-color="blue2"] {
  background-color: #1269db;
}
.btnSwitch button[data-color="purple2"] {
  background-color: #5c55bf;
}
.btnSwitch button[data-color="light-blue2"] {
  background-color: #3697e1;
}
.btnSwitch button[data-color="green2"] {
  background-color: #2bb930;
}
.btnSwitch button[data-color="orange2"] {
  background-color: #ff9e27;
}
.btnSwitch button[data-color="red2"] {
  background-color: #ea4d56;
}
.btnSwitch button[data-color="bg1"] {
  background-color: #fafafa;
}
.btnSwitch button[data-color="bg2"] {
  background-color: #fff;
}
.btnSwitch button[data-color="bg3"] {
  background-color: #f1f1f1;
}

@media screen and (max-width: 550px) {
  .table-typo tr td {
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .table-typo tr td:first-child p {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: 576px) {
  .custom-template .custom-content {
    overflow: auto;
  }
  .form-show-notify > .text-right,
  .form-show-validation > .text-right {
    text-align: left !important;
  }
}

@media screen and (max-width: 400px) {
  .custom-template {
    width: 85% !important;
    right: -85%;
  }
}

.accordion-button {
  background-color: transparent !important;
  border: 0;
  width: 100%;
  text-align: left;
  padding: 0;
}
.link-collapse {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  display: flex;
  flex-direction: column;
  padding: 7px 0;
}
.accordion-collapse {
  margin: 0 15px !important;
  padding: 0 15px !important;
}
.nav-link {
  display: block;
  padding: 8px 15px;
  font-size: 16px;
  font-weight: 600;
  color: #002866;
  text-decoration: none;
  font-family: var(--font-primary);
}

.nav-primary .nav-item {
  margin-bottom: 12px;
}
.slider--section {
  width: 35%;
}
.form--section {
  width: 65%;
  background-color: #fff;
}
.carousel-item img {
  height: 100vh;
  opacity: 0.4;
}
.carousel-caption h5 {
  font-size: 1.54rem;
  font-weight: 400;

  margin-bottom: 1.5rem;
}
.carousel-caption {
  position: absolute;
  left: 50%;
  top: 50%;
  right: 0;
  z-index: 10;
  transform: translate(-50%, -15%);
}
.carousel-caption p {
  font-size: 1rem;
  line-height: 1.54;
}
.overlay1 {
  background-image: linear-gradient(135deg, #667eea, #764ba2) !important;
}
.overlay2 {
  background-image: linear-gradient(160deg, #ccbb80, #f4967c) !important;
}
.overlay3 {
  background-image: linear-gradient(90deg, #434343 0, #000) !important;
}

.app-logo {
  margin-bottom: 3rem;
  height: 70px;
}
label {
  font-size: 0.88rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
}
.app-login-box h2 {
  opacity: 0.6;
  margin-bottom: 0;
}
.app-login-box p {
  font-size: 1rem;
}
.rounded {
  border-radius: 35px !important;
}
.pointer,
.pointer:hover {
  cursor: pointer;
  text-decoration: none;
}
.btn-link:hover,
.btn-link {
  color: #002866;
  text-decoration: none;
}
.ant-checkbox-input,
.ant-checkbox-inner {
  display: none !important;
}

.ant-table-thead > tr > th {
  background: #03367d !important;
  color: #fff !important;
}
@media (max-width: 575px) {
  .form--section {
    width: 100%;
    height: 100vh;
  }
  .modal-dialog {
    max-width: 100%;
    margin: 1.75rem auto;
  }
}

.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}

.playIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 38px;
  width: 55px;
  background-color: var(--primary);
  border-radius: 12px;
}

.playIcon .fa {
  font-size: 20px;
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 38px;
}
#videoDeleteIcon {
  color: #fff;
  position: absolute;
  top: -8px;
  right: -6px;
  background: #000;
  border-radius: 50%;
  height: 24px;
  font-size: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#imageDeleteIcon {
  color: #fff;
  position: absolute;
  top: -8px;
  right: 0px;
  background: #000;
  border-radius: 50%;
  height: 24px;
  font-size: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
#imageDeleteIcon1 {
  color: #fff;
  position: absolute;
  top: -12px;
  right: 30px;
  background: #000;
  border-radius: 50%;
  height: 24px;
  font-size: 12px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

/* .ant-table-wrapper {
  clear: both;
  max-width: 100%;
  width: 100% !important;
  overflow-x: auto;
}

.fund-img .ant-upload-list-picture-card-container {
  width: 150px !important;
  min-height: 110px !important;
}
.ant-upload-list-item-info {
  width: 150px !important;
  height: 120px !important;
}
.fund-img .ant-upload-list-item-thumbnail {
  border-radius: 10px !important;
}
.fund-img .ant-upload {
  border-radius: 10px !important;
}

.fund-img .ant-upload-list.ant-upload-list-picture-card {
  width: 180px !important;
  border-radius: 10px !important;
}
.fund-img .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: white !important;
  border-radius: 10px !important;
}

.fund-img .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px !important;
}

.fund-img img {
  width: 150px !important;
  object-fit: cover !important;
  border-radius: 10px !important;
}
.fund-img .ant-upload.ant-upload-select-picture-card {
  width: 150px !important;
  height: 120px !important;
} */
.fund-img .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  padding: 0px !important;
}
.fund-img img {
  width: 150px !important;
  object-fit: cover !important;
  border-radius: 10px !important;
}
.fund-img .ant-upload-list-picture-card .ant-upload-list-item-error {
  border-color: transparent !important;
  border-radius: 10px !important;
}
:where(.css-dev-only-do-not-override-14wwjjs).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-14wwjjs).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-error,
:where(.css-dev-only-do-not-override-14wwjjs).ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item-error {
  border-color: #d9d9d9 !important;
}
.ant-tooltip,
.anticon-eye,
.ant-upload-list-item-actions button {
  display: none !important;
}

.ant-upload-list-item-container,
.ant-upload-list-item.ant-upload-list-item-error,
.ant-upload-list-item-thumbnail,
.ant-upload.ant-upload-select,
.ant-upload-list.ant-upload-list-picture-card {
  height: 180px !important;
  width: 180px !important;
  background-color: #e7e7e7 !important;
  border-radius: 50% !important;
  position: relative;
  /* margin: 0 auto !important; */
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary);
}
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before,
:where(
    .css-dev-only-do-not-override-14wwjjs
  ).ant-upload-wrapper.ant-upload-picture-circle-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item::before {
  position: absolute;
  z-index: 1;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
  background-color: transparent;
  opacity: 0;
  transition: all 0.3s;
  content: " ";
}
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item,
.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  border: 0px !important;
}
.fund-card:hover:not(:has(*:hover)) {
  opacity: 0.7;
  transition: background 0.3s, border-radius 0.3s, opacity 1s;
}
.ant-table-wrapper {
  clear: both;
  max-width: 100%;
  width: 100% !important;
  overflow-x: auto;
}
.fund-img {
  position: relative;
  width: 190px;
}
.fund-image-delete {
  z-index: 999;
  right: -10px !important;
}
.fund-img .ant-upload {
  border-radius: 10px !important;
  /* width: 130px !important;
  height: 150px !important; */
}
.fund-img .ant-upload-list.ant-upload-list-picture-card {
  width: 150px !important;
  height: 120px !important;
  overflow: hidden;
  border-radius: 10px !important;
}
.fund-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container,
.profile-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-container {
  display: flex;
  width: 102px;
  height: 102px;
  margin-block: 0 8px;
  margin-inline: 0 8px;
  vertical-align: top;
}
.fund-img
  .ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  img {
  position: static;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: none !important;
}
.fund-img .ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
  position: relative;
  height: 66px;
  padding: 0px;
  border: 1px solid #d9d9d9;
  border-radius: 2px;
}
.fund-img .ant-upload-list-picture-card-container {
  display: inline-block;
  width: 150px;
  height: 120px;
}
.fund-img .ant-upload-list-picture-card-container {
  margin: 0 0px 0px 0;
}

.dashboardflex {
  display: flex;
  gap: 8px;
  justify-content: space-around;
  flex-wrap: wrap;
  align-items: stretch;
  align-self: stretch;
}
.d-flex-item {
  width: 250px;
  flex: 1;
}

.cardTitle,
.cardTitleBlue {
  color: #121212;
  font-weight: 600;
  margin-bottom: 0px;
  font-size: 20px;
}
.cardTitleBlue {
  color: #002866;
}
.cardIcon {
  width: 50px;
}
.feed-divider {
  border-bottom: 1px solid rgb(0, 0, 0, 0.1);
  width: 100%;
  margin: 10px 10px;
}
.feed-container {
  height: 400px;
  overflow: auto;
}
.top-cause-container {
  height: 415px;
  overflow-y: auto;
}
.top-donor-container {
  height: 522px;
  overflow-y: auto;
}
.top-donors .ant-table-thead > tr > th {
  background-color: rgba(176, 179, 178, 0.012) !important;
  color: #4c4c4c !important;
  font-weight: 600;
}
.top-donors .ant-pagination,
.top-donors .ant-table-pagination,
.top-donors .ant-table-pagination-right {
  display: none;
}
.swal-button--confirm {
  background-color: #002866 !important;
  color: white !important;
  border: 1px solid #002866;
}
.swal-button--confirm:hover {
  background-color: #f9cb40 !important;
  color: #002866 !important;
  border: 1px solid #f9cb40;
}
.swal-button--danger {
  background-color: #002866 !important;
  color: white !important;
  border: 1px solid #002866;
}
.swal-button--cancel {
  background-color: transparent !important;
  color: #002866 !important;
  border: 1px solid #002866;
}
.swal-button--cancel:hover {
  background-color: #f9cb40 !important;
  color: #002866 !important;
  border: 1px solid #f9cb40;
}
.action-button-view {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #002866 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid #002866;
}
.action-button-edit {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #f9cb40 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase !important;
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid #f9cb40;
  border-color: #f9cb40 !important;
}
.action-button-withdraw {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #31ce36 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid #31ce36;
}
.action-button-delete {
  background-color: rgb(255, 255, 255) !important;
  border-radius: 8px;
  font-weight: 600;
  color: #dc3545 !important;
  justify-content: center;
  font-size: 14px;
  text-transform: uppercase;
  padding: 4px 8px;
  cursor: pointer;
  border: 1px solid #dc3545 !important;
}
.action-button-view:hover {
  background-color: #002866 !important;
  color: white !important;
  border-color: #002866 !important;
}
.action-button-edit:hover {
  background-color: #f9cb40 !important;
  color: white !important;
  border-color: #f9cb40 !important;
}
.action-button-withdraw:hover {
  background-color: #31ce36 !important;
  color: white !important;
  border-color: #31ce36 !important;
}
.action-button-delete:hover {
  background-color: #dc3545 !important;
  color: white !important;
  border-color: #dc3545 !important;
}
.ant-table {
  font-size: 18px !important;
  color: black !important;
}
.form-group-default label:not(.error) {
  font-size: 12px !important;
  color: #000 !important;
}
.form-group-default .form-control {
  color: #000 !important;
}
.sidebar .nav > .nav-item a p,
.sidebar[data-background-color="white"] .nav > .nav-item a p {
  font-size: 16px;
  color: #000;
}
.sidebar .user .info a > span,
.sidebar[data-background-color="white"] .user .info a > span {
  font-size: 16px;
  color: #000;
}
.sidebar .nav > .nav-item a i,
.sidebar[data-background-color="white"] .nav > .nav-item a i {
  color: #000;
  font-size: 18px;
}
.forgotPasswordAuth {
  cursor: pointer;
  text-decoration: underline;
  margin: 16px 0;
}

.forgotPasswordAuth:hover,
.forgotPasswordAuth a:hover {
  color: #002866;
  font-weight: 500;
}
@media (max-width: 400px) {
  .button-responsive {
    display: flex;
    flex-direction: column;
  }
}
